<template>
  <v-app>
    <!-- alert begin -->
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success',
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <!-- alert end  -->

    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>End of Contract</h1>
        </div>

        <v-expansion-panels class="mb-5">
          <v-expansion-panel>
             <v-expansion-panel-header>
              Exit Date
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="10" offset="1">
          <v-form ref="endOfContract" v-model="valid">
          <v-row no-gutters>
            <v-col>
                 <TextField  :type="'date'" v-model="exitDate" :label="'Exit Date'"/>
            </v-col>
            <v-col cols="6" class="pr-1">
               <TextField  :type="'number'" v-model="exitMonth" :disable='true' :label="'Exit Month'"/>
            </v-col>
            <v-col cols="6" class="pl-1">
               <TextField  :type="'number'" v-model="exitYear" :disable='true' :label="'Exit Year'"/>
            </v-col>
          </v-row>
        </v-form>
        </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Terminal Items  -->

      <!-- end terminal items  -->

        <!-- <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <Button
              :label="'Generate Benefit'"
              :btnType="'Submit'"
              @onClick="generateBenefit"
              :disabled="!valid"
              :isLoading="isLoading"
              class=""
            />
          </v-col>
        </v-row> -->

        <!-- table  -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
            :headers="BenefitHeader"
            :items="Benefits"
            :single-expand=false
            item-key="employee.id"
            show-expand
            >

            <template v-slot:expanded-item="{headers, item }">
              <td :colspan="headers.length" class="pa-0">
                  <table class="sub-table">
                    <tr class="table-header">
                      <td>Salary Component</td>
                      <td>Amount</td>
                      <td>Multiplier</td>
                    </tr>
                    <tr class="table-body" v-for="(benefits, index) in item.benefits" :key="index">
                      <td>{{benefits.description}}</td>
                     <td>{{benefits.amount}}</td>
                     <td>  <input type="text" class="input" v-model="benefits.multiplier" /></td>
                    </tr>
                  </table>

              </td>

            </template>

            </v-data-table>
          </v-col>
        </v-row>

         <!-- table end  -->
      </div>
    </div>

    <v-row>
      <v-col cols="11 mt-5" class="d-flex justify-end">
        <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="!valid"
          :isLoading="isLoading"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
      </v-col>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>

import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField.vue'
import { benefitService } from '@/services'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_TERMINALBENEFIT } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      dialog: false,
      alertType: '',
      valid: false,
      terminalItemValid: false,
      search: '',
      isLoading: false,
      exitDate: '',
      exitMonth: '',
      exitReasonId: 5,
      exitYear: '',
      Benefits: [

      ],
      BenefitHeader: [
        {
          text: 'Employee',
          value: 'employee.firstName'
        },        
        {
          text: 'Email',
          value: 'employee.email'
        }
      ]
    }
  },
  watch: {
    'exitDate': function (val) {
      this.exitMonth = (new Date(val).getMonth() + 1).toString()
      this.exitYear = new Date(val).getFullYear().toString()
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    loadContractEmployees () {
      benefitService.endOfContract(1).then(result => {
        this.Benefits = result.data
      }).catch(error => {
        console.log(error)
        this.showAlertMessage('Fail to load Contract Employee', 'error')
      })
    },
    onSave () {
      const data = []
      this.Benefits.forEach(ben => {
        let terminalBenefitItems = []
        ben.benefits.forEach(terminalBen => {
          terminalBenefitItems.push({ multiplier: terminalBen.multiplier, amount: terminalBen.amount, salaryComponentId: terminalBen.salaryComponentId })
        })
        data.push({ EmployeeId: ben.employee.id, exitReasonId: this.exitReasonId, exitDate: this.exitDate, terminalBenefitItems })
      })

      this.isLoading = true

      console.log(data)

      this.$store.dispatch(CREATE_TERMINALBENEFIT, data).then(() => {
        this.showAlertMessage('End of Contract successful', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('End of Contract failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.endOfContract.reset()
      this.formData.personnelName = ''
      this.formData.onBoardingDate = ''
      this.formData.exitDate = ''
      this.formData.salary = ''
      this.formData.workLocation = ''
      this.formData.costCenter = ''
    }
  },
  mounted () {
    this.loadContractEmployees()
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style{
  border-color: #9f9a9a;
}
.table-header td{
  font-weight: bold;
}
.table-body td {
  background-color: white;
}
.sub-table {
  border: 1px solid #efefef;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 10px;
}
</style>
